<script setup>
const props = defineProps({
  id: String,
  name: String,
  inputType: {
    type: String,
    default: 'text'
  },
  placeholder: String,
  validate: Function,
  validateOn: String,
  required: Boolean,
})
const emit = defineEmits(['enterPress', 'keyUp'])
const input = ref(null);

defineExpose({input})

const handleChange = (e, fieldName)=>{
  emit('keyUp', {event: e, name: fieldName})
}
</script>

<template>
  <input
    ref="input"
    :type="inputType"
    :placeholder="placeholder || null"
    :name="name"
    :id="id"
    @keyup="handleChange($event, name)">
</template>

<style lang="postcss">

</style>